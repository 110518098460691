import request from './request';
import qs from 'qs'

// 不同环境的切换
// let base = 'http://127.0.0.1:8090';//开发
let base = 'https://gouxiong360.top';
// if (process.env.NODE_ENV == 'development') {// 开发
//     base = 'http://172.11.11.11:8800';
//   }
//   else if (process.env.NODE_ENV == 'debug') {
//     base = 'https://172.11.11.11:8800';
//   }
//   else if (process.env.NODE_ENV == 'production') {// 线上环境
//     base = 'https://xxx.co';
//   }

let contentType1 = 'application/x-www-form-urlencoded';
let contentType2 = 'application/json';
   /**
    *分装适合不同请求类型的方法
     * @param method 请求类型
     * @param url 请求地址
     * @param params 请求参数
     * @param header 请求头
     */
function Request(method, url, params, header) {
  url = `${base}` + url;
  header = { 'Content-Type': header || 'application/json' };
  let config = {};
  switch (method) {
      case 'get':
        config = {
          method: method,
          url: url,
          headers: header
        }
        if(params) config.params = params
        return request(config)
      case 'delete':
        config = {
          method: method,
          url: url,
          headers: header
        }
        if(params) config.params = params
        return request(config)
      case 'post':
        config = {
          method: method,
          url: url,
          headers: header
        }
        if(params) config.data = JSON.stringify(params)
        return request(config)
      case 'put':
        config = {
          method: method,
          url: url,
          headers: header
        }
        if(params) config.data = qs.stringify(params)
        return request(config)
  }
}
// export default {
//     // 调用封装好的方法，把不同的请求暴露出去
//     loginIn(params) { return Request('post', '/api/v1/login', params, contentType1) },
//     loginOut(params) { return Request('post', '/api/v1/logout', params, contentType2) },
//     TestInfo(params) { return Request('get','/test',params,contentType1) }
//  }

export const testAPI = (params)=>{
   return Request('get','/api/test',params,contentType1) 
}

export const getCaptcha = (params)=>{
  return Request('get','/api/captchaImage',params,contentType1)
}

export const subscribe = (params)=>{
  return Request('post','/api/subscribe',params,contentType2)
}

export const givesuggestion = (params)=>{
  return Request('post','/api/user/suggestion',params,contentType2)
}


 
export const activeEmailApi = (params)=>{
  return Request('get','/api/active/'+params,null,contentType1) 
}


export const getTargetInfo = (params)=>{
  return Request('get','/api/targetId/'+params,null,contentType1) 
}