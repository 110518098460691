<template>
<div>
 <div v-if="loading">
     <b-button variant="primary" disabled>
        <b-spinner small type="grow"></b-spinner>
         激活中....{{uuid}}
     </b-button>
     <b-row style="margin-top:10px;padding:5px;">
        <b-col>
            <b-alert :show="iserror" variant="danger">{{ errorMsg}}</b-alert>
         </b-col>
     </b-row>
 </div>
 <div v-else>
 <b-container>
    <b-row>
        <b-col>
            <b-alert show variant="success">
                <h4 class="alert-heading">Well done!</h4>
                <p>
                激活成功！
                </p>
                <hr>
                <p class="mb-0">
                后续系统将自动给您发送相关信息变更邮件，请您随时注意邮箱，有可能系统通知邮件到您的“垃圾箱”中.
                </p>
            </b-alert>
        </b-col>
    </b-row>
  </b-container>
 </div>
 
</div>
</template>
<script>
import { activeEmailApi } from '@/request/api.js'
export default {
  name: 'ActiveEmail',
  data(){
    return {
        loading:true,
        uuid:'',
        iserror:false,
        errorMsg:'',
    }
  },
  methods:{
    sendActiveEmailApi(){
        activeEmailApi(this.uuid).then((res)=>{
            console.log(res)
            if(res.data.code != 200){
                this.iserror = true
                this.errorMsg = res.data.msg
                this.loading = true
            }else{
                this.iserror = false
                this.loading = false
            }
        })
    }
  },
  mounted(){
     this.uuid = this.$route.params.uuid
     this.sendActiveEmailApi()
  }
}
</script>
