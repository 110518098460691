<template>
    <div>
   <b-container fluid>
       <b-row>
         <b-col>
            <img alt="Vue logo" src="../assets/logo.png" style="height:160px;">
         </b-col>
       </b-row>
       <div v-if="weihuStatus">
          <b-alert show>系统正常维护中，请稍后刷新重试...！</b-alert>
       </div>
       <div v-else>
         <div v-if="loadingTargetInfo">
            <b-button variant="primary" disabled>
              <b-spinner small></b-spinner>
              <span class="sr-only">加载中...</span>
            </b-button>
          </div> 
          <div v-else>
            <p>VIP用户，欢迎您订阅：【 {{ name }} 】的信息</p>
          <b-alert  variant="danger" 
            dismissible
            fade
            :show="showDismissibleAlert"
            @dismissed="showDismissibleAlert=false">{{alertMsg}}</b-alert>
            <b-row>
                <b-col>
                  <b-form-input 
                  v-model="email" 
                  type="email"
                  placeholder="请输入您真实的个人邮箱" ></b-form-input>
                </b-col>
            </b-row>
            <b-row style="margin-top:10px">
              <b-col>
                 <img class="getCaptcha" :src="codeUrl" @click="getCode">
              </b-col>
              <b-col style="padding:10px" align-self="start">
                  <b-form-input v-model="code" placeholder="验证码"></b-form-input>
              </b-col>
            </b-row>
            <div v-if="showDismissibleAlert">
              <b-row style="margin-top:10px;padding:10px;">
                  <b-button pill block variant="danger" @click="subscribe" :disabled="showDismissibleAlert" >无法订阅
                  </b-button>
              </b-row> 
            </div>
            <div v-else>
              <b-row style="margin-top:10px;padding:10px;">
                  <b-button pill block variant="primary" @click="subscribe" >
                    <b-icon icon="credit-card" aria-hidden="true"></b-icon> 订 阅
                    <b-spinner small type="grow" v-if="issubscribe"></b-spinner>
                  </b-button>
                </b-row> 
            </div>
            <div style="margin-top:5px">
            </div>
            <b-card header="订阅优势" header-bg-variant="info">
              <b-list-group>
                <b-list-group-item href="#">最新的地址及时通知</b-list-group-item>
                <b-list-group-item href="#">最快的资讯及时触达</b-list-group-item>
                <b-list-group-item href="#">VIP体验 不迷路 </b-list-group-item>
              </b-list-group>
            </b-card>
          </div>  
          <div style="margin-top:5px">
            <b-button pill variant="success" @click="gotosuggestionPage">
                <b-icon icon="chat-left-text" aria-hidden="true"></b-icon> 用户建议
            </b-button>
          </div>
       </div>
   </b-container>
    </div>
</template>


<script>
import { testAPI, getCaptcha,getTargetInfo,subscribe } from '@/request/api.js'
export default {
  name: 'SubScribe',
  data(){
    return {
        targetId:'',
        email:'',
        name:'',
        codeUrl: '',
        uuid:'',
        code:'',
        issubscribe:false,
        alertMsg:'',
        showDismissibleAlert:false,
        weihuStatus:false,
        loadingTargetInfo:true,
    }
  },
  methods:{
    initTest(){ 
        testAPI().then((res)=>{
            console.log(res)
         if(res == undefined){
                this.weihuStatus = true
            }
        }).catch(()=>{
            this.weihuStatus = true
        })
    },
    subscribe(){
        this.issubscribe = true;
        let subObj = {
            "email":this.email,
            "code":this.code,
            "uuid":this.uuid,
            "targetId":this.targetId
        }
        
        subscribe(subObj).then((result) => {
            if(result.data.code != 200){
                this.showDismissibleAlert = true
                this.alertMsg = result.data.msg
            }else{
               //跳转到成功页面
               this.$router.push({name:'Success'})
            }
            
        }).catch((err) => {
            console.log(err)
        }).finally(()=>{
            this.issubscribe = false;
            setTimeout(() => {
              this.showDismissibleAlert = false
            }, 1000);
        });

    },
    getsubscribeTargetInfo(){
        this.loadingTargetInfo = true
        this.targetId = this.$route.params.targetId
        getTargetInfo(this.targetId).then(res=>{
            if(res.data.code !=200){
                this.showDismissibleAlert = true
                this.alertMsg = res.data.msg
            }else{
                this.name = res.data.data.name
                this.targetId = res.data.data.targetId
            }
        }).finally(()=>{
          this.loadingTargetInfo = false
        })
    },
    gotosuggestionPage(){
      this.$router.push({name:'SuggestionPost'})
    },
    getCode(){
        getCaptcha().then(res=>{
            this.codeUrl = 'data:image/gif;base64,' + res.data.img
            this.uuid = res.data.uuid
        })
    }
  },
//   Created(){
//     this.initTest()
//   },
  mounted(){
    this.initTest()
    this.getCode()
    this.getsubscribeTargetInfo()
  },
}
</script>