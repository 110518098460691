import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld';
import SubScribe from '@/components/SubScribe';
import SubScribeSuccess from '@/components/SubScribeSuccess';
import ActiveEmail from '@/components/ActiveEmail';
import SuggestionPost from '@/components/Suggestion.vue';


Vue.use(Router)

export default new Router({
    routes:[
        {
            path:'/',
            name:'HelloWorld',
            component: HelloWorld
        },
        {
            path:'/subScribe/:targetId',
            name:'SubScribe',
            component: SubScribe
        },
        {
            path:'/suggestion',
            name:'SuggestionPost',
            component: SuggestionPost
        },
        {
            path:'/success',
            name:'Success',
            component: SubScribeSuccess
        },
        {
            path:'/active/:uuid',
            name:'active',
            component: ActiveEmail
        },
    ]
})