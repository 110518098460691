<template>
<b-container>
    <div v-if="subbmitFail">
        <b-alert variant="success" show>欢迎您提交建议，我们将不断改进</b-alert>
        <b-row>
            <b-col>
                <b-form-textarea
                id="textarea"
                v-model="suggestion.content"
                placeholder="请写下你对我们的建议和吐槽..."
                rows="3"
                max-rows="10"
            ></b-form-textarea>
            </b-col>
        </b-row>
        <b-row style="margin-top: 10px;">
        <b-col cols="12">
            <b-form-input type="email" v-model="suggestion.email" placeholder="个人邮件（可不填）"></b-form-input>
        </b-col>
        </b-row>
        <b-row style="margin-top:10px">
            <b-col>
                <img class="getCaptcha" :src="codeUrl" @click="getCode">
            </b-col>
            <b-col style="padding:10px" align-self="start">
                <b-form-input v-model="suggestion.code" placeholder="验证码"></b-form-input>
            </b-col>
        </b-row>
        <b-row style="margin-top:10px;padding:10px;">
            <b-button pill block variant="primary" @click="givesuggestion" :disabled="issuggesion">
            <b-icon icon="cursor" aria-hidden="true"></b-icon> 提交建议
            <b-spinner small type="grow" v-if="issuggesion"></b-spinner>
            </b-button>
        </b-row> 
    </div>
    <div v-else>
        <h1>提交成功</h1>
        <b-icon icon="envelope" animation="spin-reverse" font-scale="4" variant="success"></b-icon>
    </div>
    
    <b-row style="margin-top:10px;padding:10px;">
        <b-button pill block variant="danger" @click="back" >
        <b-icon icon="back" aria-hidden="true"></b-icon> 返回
        </b-button>
    </b-row> 

    <b-row style="margin-top:10px;padding:10px;">
        <b-button variant="link" @click="backhome">首页</b-button>
    </b-row> 


</b-container>

</template>
<script>
import {  getCaptcha,givesuggestion } from '@/request/api.js'
  export default {
   name: 'SuggestionPost',
   data(){
     return {
        codeUrl:'',
        issuggesion:false,
        subbmitFail:true,
         suggestion:{
            content:'',
            email:'',
            code:'',
            uuid:'',
         }
     }
   },
   methods:{
    getCode(){
        getCaptcha().then(res=>{
            this.codeUrl = 'data:image/gif;base64,' + res.data.img
            this.suggestion.uuid = res.data.uuid
        })
    },
    givesuggestion(){
        this.issuggesion = true
        this.subbmitFail = true
        givesuggestion(this.suggestion).then(res=>{
            if(res.data.code !== 200){
                this.$bvToast.toast(res.data.msg,{
                    title:'错误提示',
                    variant: 'danger',
                    solid: true,
                    noCloseButton: true
                })
            }else {
                this.subbmitFail = false
            }
        }).finally(()=>{
            setTimeout(() => {
                this.issuggesion = false
            }, 1000);
        })
    },
    back(){
        this.$router.back()
    },
    backhome(){
        this.$router.push('/')
    }
   },
   mounted(){
    this.getCode()
   }
}

</script>