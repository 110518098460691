<template>
  <b-container class="bv-example-row">
    <div v-if="loadingbegin">
      <div class="text-center">
          <b-button variant="primary" disabled>
            <b-spinner small type="grow"></b-spinner>
              激活邮件发送中...
          </b-button>
      </div>
      <div style="margin-top:5px">
        <b-progress :value="value" :max="max" show-progress animated></b-progress>
      </div>
      <b-container style="margin-top:20px">
        <b-row>
          <b-alert show variant="info">请检查邮箱是否收到激活邮件！</b-alert>
        </b-row>
      </b-container>
    </div>
    <div v-else>
        <b-alert show variant="success">
            <h4 class="alert-heading">订阅请求已经提交!</h4>
            <p>
            系统将给您发送电子邮件！.请收到激活邮件后，按照提示点击 激活链接
            </p>
            <b-icon icon="check-circle" variant="success" tyle="width: 120px; height: 120px;"></b-icon>
              
            <hr>
            <p class="mb-0">
              温馨提示：如果2分钟还未收到邮件，请检查激活邮件是否再垃圾箱！
            </p>
          </b-alert>
    </div>
    <b-row>
      <b-col>
            <b-button variant="outline-success" @click="goup">上一步</b-button>
        </b-col>
        <b-col>
            <b-button variant="outline-success" @click="gohome">返回首页</b-button>
        </b-col>
      </b-row>
        
  </b-container>
</template>
<script>
export default {
  name: 'SubScribeSuccess',
  data () {
    return {
      loadingbegin:true,
      value: 10,
      max: 100,
      interlock:undefined,
    }
  },
  created(){
      this.interlock = setInterval(() => {
      this.value++;
      if(this.value>=100){
        clearInterval(this.interlock)
         this.loadingbegin = false
      }
    }, 200);
  },
  methods:{
    gohome(){
       this.$router.push({name:'HelloWorld'})
    },
    goup(){
      this.$router.back()
    },
  }
}
</script>