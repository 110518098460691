<template>
  <b-container class="bv-example-row">
      <img alt="Vue logo" src="../assets/logo.png" style="height:160px;">
      <div class="hello">
        <b-alert show variant="primary">欢迎使用狗熊信息订阅系统</b-alert>
        <b-card
        border-variant="primary"
        header="介绍"
        header-bg-variant="primary"
        header-text-variant="white"
        align="center"
      >
        <b-card-text>第三方独立的订阅系统，粉丝管理、信息管理、客户管理，简化你的商业流程<br>
          无广告推送、无骚扰.</b-card-text>
      </b-card>
        <p>
          <ul>
          <li><a href="https://mail.google.com" target="_blank" rel="noopener">Email Google</a></li>
          <li><a href="https://mail.qq.com" target="_blank" rel="noopener">QQ服务</a></li>
           <li><a href="https://Google.com" target="_blank" rel="noopener">Google</a>.</li>
        </ul>
        </p>
        <b-button pill variant="success" @click="gotosuggestionPage">
            <b-icon icon="chat-left-text" aria-hidden="true"></b-icon> 用户建议
         </b-button>
         <div style="margin-top: 5px;">
          <b-alert show variant="danger">由于机房故障，机房没有做数据备份，原始数据被清理，麻烦大家重新订阅！</b-alert>
         </div>
      </div>
 
  </b-container>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods:{
    gotosuggestionPage(){
      this.$router.push({name:'SuggestionPost'})
    }
 }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
