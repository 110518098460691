import axios from "axios"
import router from "@/router/index"

// 提示方法（自己写）
let open1 = msg => {
    this.$message({
      showClose: true,
      message: msg,
      type: 'error'
    });
  }
   //跳转登录页方法
  const toLogin = () => {
     // 做一个判断如果在登录页就不重复调用
    if (router.currentRoute.fullPath != '/login') {
      router.replace({
        path: '/login'
      });
    }
  }
  /**
   * 请求失败后的错误统一处理，这里可以根据项目的错误处理需求来分情况，
   如果是除了status：200，所有错误一致处理的情况，可以省略这个方法
   * @param {Number} status 请求失败的状态码
   */
  const errorHandle = (status, others) => {
    // 状态码判断
    switch (status) {
      // 401: 未登录状态，跳转登录页
      case 401:
        open1('请登录');
        toLogin();
        break;
      case 403:
        open1('登录过期，请重新登录');
        setTimeout(() => {
          toLogin();
        }, 1000);
        break;
      // 404请求不存在
      case 404:
        open1('请求不存在');
        break;
      default:
        console.log(others);
    }
  }
  /**
   * 请求成功处理，主要拦截 code 不等于 200 的情况
   * @param {*} res 是请求成功的数据
   */
  function filterResponse(res) {
    switch (res.data.code) {
      // 请求成功
      case 200:
        return Promise.resolve(res);
      case 401:
        console.log('登录过期', res);
        toLogin();
        return Promise.reject(res);
      case 500:
        return Promise.resolve(res);
      default:
        break;
    }
  }

  //1、 创建axios实例
  var instance = axios.create();
  instance.defaults.timeout = 30000;
  // 带上请求身份凭证cookie(解决跨域的时候没有携带cookie的问题)
  instance.defaults.withCredentials = true
  // 2、请求拦截器
  instance.interceptors.request.use(function(config){
      //发送请求前做些什么
      return config;
  },function(error) {
      // 对请求错误做些什么
      return Promise.reject(error);
  })
  // 3、请求响应拦截器
  instance.interceptors.response.use(
    // 请求成功
    res =>  res.status === 200 ? filterResponse(res) : Promise.reject(res),
    // 请求失败
    error => {
      const { response } = error;
      if (response) {
        // 请求已发出，失败
        errorHandle(response.status, response.data.message);
        return Promise.reject(response);
      }
    })
  export default instance;